<!--  -->
<template>
    <div class="row clearfix">
        <div>
            <div class="table-responsive" id="DomPdf">
                <h2 style="text-align: center "><b>用户个人信息基本表</b></h2>

                <!-- 上传excel文件 -->
                <el-upload action="" :auto-upload="false" :on-change="handleFileChange" :show-file-list="false">
                    <el-button type="success" plain>上传Excel文件</el-button>
                </el-upload>
                <el-tabs v-model="activeTab" >
                    <el-tab-pane label="基本信息" name="BaseInfo">
                        <h4>基本信息</h4>

                        <el-row>
                            <el-descriptions :column="3" border :labelStyle="labelStyle" label-class-name="my-label"
                                content-class-name="my-content">
                                <el-descriptions-item v-for="item in basic" :key="item.label" :label="item.label">
                                    {{ item.value }}
                                </el-descriptions-item>
                            </el-descriptions>
                        </el-row>
                        <h4>既往史</h4>
                        <el-table :data="History" border :span-method="objectSpanMethod"
                            :header-cell-style="tableHeaderCellStyle" :cell-style="{ padding: '3px' }">
                            <el-table-column prop="item" label="既往史">
                            </el-table-column>
                            <el-table-column label="详细信息">
                                <el-table-column prop="label" label="项目">
                                </el-table-column>
                                <el-table-column prop="value" label="确诊时间">
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                        <el-table :data="Family" border :header-cell-style="tableHeaderCellStyle"
                            :cell-style="{ padding: '3px' }">
                            <el-table-column prop="label" label="家族史">
                            </el-table-column>
                            <el-table-column prop="value" label="内容">
                            </el-table-column>
                        </el-table>
                        <el-table :data="Other" border :header-cell-style="tableHeaderCellStyle"
                            :cell-style="{ padding: '3px' }">
                            <el-table-column prop="label" label="其它史">
                            </el-table-column>
                            <el-table-column prop="value" label="内容/(有/无)">
                            </el-table-column>
                        </el-table>
                        <h4>生活环境</h4>
                        <el-row>
                            <el-descriptions :column="3" border :labelStyle="labelStyle">
                                <el-descriptions-item v-for="item in Environment" :key="item.label" :label="item.label">
                                    {{ item.value }}
                                </el-descriptions-item>
                            </el-descriptions>
                        </el-row>
                        <h4 v-if="genderFlag">怀孕情况</h4>
                        <el-row v-if="genderFlag">
                            <el-descriptions :column="3" border :labelStyle="labelStyle">
                                <el-descriptions-item v-for="item in Pregnant" :key="item.label" :label="item.label">
                                    {{ item.value }}
                                </el-descriptions-item>
                            </el-descriptions>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="孕婴信息" name="BabyInfo">
                        <div v-for="(group, groupKey) in filteredBabyInfo" :key="groupKey">
                            <h3>{{ BabyInfo_key[groupKey] }}</h3>
                            <!-- <div v-for="(item, index) in group" :key="index">
                                <span>{{ item.name }}: </span>
                                <span>{{ item.value || '（空）' }}</span>
                            </div> -->
                            <el-descriptions :column="2" border :labelStyle="labelStyle_baby"
                                label-class-name="my-label" content-class-name="my-content">
                                <el-descriptions-item v-for="item in group" :key="item.label" :label="item.name">
                                    {{ item.value }}
                                </el-descriptions-item>
                            </el-descriptions>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <br>
            <!-- <el-button type="primary" plain >修改个人信息</el-button>
            <el-button type="primary" plain >加入小屋</el-button>
                        <el-button type="primary" plain >既往病史录入</el-button> -->
            <el-button type="primary" plain @click="getPdf('#DomPdf')">下载个人信息表</el-button>
            <el-button type="primary" plain @click="dialogVisible = true">修改</el-button>
            <!-- <el-button type="primary" plain onclick="download()">下载个人信息表</el-button> -->
            <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
                <div>请前往【心狗健康服务——心狗云医生】小程序进行修改</div>
                <img style="zoom:0.8" src="../../../../src/assets/img/applet_QRcode.jpg" class="aligncenter" />
                <div> 【操作步骤】：我的->个人信息->修改个人信息 </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="dialogVisible = false">我知道了</el-button>
                </span>
            </el-dialog>


        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import * as XLSX from 'xlsx';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    // props传递属性
    props: {
        userId: Number,  // 【其他平台】读取的属性
    },
    data() {
        //这里存放数据
        return {
            activeTab: 'BaseInfo',  // BaseInfo, BabyInfo
            uid: this.userId,
            // 基本信息
            basic: [
                { label: '姓名', value: 'xxx', name: 'realName' },
                // { label: '用户编号', value: 'xxx' ,name: 'uid'},
                { label: '年龄', value: '00', name: 'age' },
                { label: '性别', value: '-', name: 'gender' },
                { label: '身高(cm)', value: '-', name: 'height' },
                { label: '体重(kg)', value: '', name: 'weight' },
                { label: '血型', value: '', name: 'bloodType' },
                { label: '出生日期', value: '-', name: 'birthday' },
                { label: '身份证号', value: '0', name: 'idCard' },
                { label: '工作单位', value: '--', name: 'company' },
                { label: '邮箱', value: '', name: 'email' },  //user
                { label: '本人电话', value: '', name: 'phone' }, //user
                // { label: '联系人姓名', value: '' ,name: ''},
                // { label: '联系人电话', value: '' ,name: ''},
                { label: '居住地编码', value: '', name: 'areaCode' },
                { label: '详细居住地', value: '', name: 'areaAddress' },
                { label: '户籍所在地编码', value: '', name: 'censusRegisterCode' },
                { label: '详细户籍所在地', value: '', name: 'censusRegisterAddress' },
                { label: '常住类型', value: '', name: 'residentialType' },
                { label: '民族', value: '', name: 'nationality' },
                { label: '婚姻状况', value: '', name: 'marry' },
                { label: '教育程度', value: '', name: 'education' },
                { label: '职业', value: '', name: 'job' },
                { label: '健康状态自我评估', value: '', name: 'selfEvaluation' },
                { label: '心理状况', value: '', name: 'mentality' },
                { label: '医疗费用支付方式', value: '', name: 'insurancePaymentMode' },
                { label: '药物过敏史', value: '', name: 'allergyHistory' },
            ],
            // 既往史

            History: [
                // 疾病史diseaseHistory
                // { item: '疾病史', label: '高血压', value: '-' },
                // { item: '疾病史', label: '糖尿病', value: '-' },
                // { item: '疾病史', label: '冠心病', value: '-' },
                // { item: '疾病史', label: '慢性阻塞性肺疾病', value: '-' },
                // { item: '疾病史', label: '恶行肿瘤', value: '-' },
                // { item: '疾病史', label: '脑卒中', value: '-' },
                // { item: '疾病史', label: '严重精神障碍', value: '-' },
                // { item: '疾病史', label: '结核病', value: '-' },
                // { item: '疾病史', label: '肝炎', value: '-' },
                // { item: '疾病史', label: '其他法定传染病', value: '-' },
                // { item: '疾病史', label: '职业病', value: '-' },
                // { item: '疾病史', label: '其他', value: '-' },
                // { item: '疾病', label: '名称1', value: '-' },
            ],
            Family: [
                // 家族史familyDisease
                { item: '家族史', label: '父亲', value: '-', name: 'father' },
                { item: '家族史', label: '母亲', value: '-', name: 'mother' },
                { item: '家族史', label: '兄弟姐妹', value: '-', name: 'brotherAndSister' },
                { item: '家族史', label: '子女', value: '-', name: 'child' },
            ],
            Other: [
                // 其他史
                { item: '其他史', label: '暴露史', value: '-', name: 'exposureHistory' },
                { item: '其他史', label: '遗传病史', value: '-', name: 'inheriDeseaseHistory' },
                { item: '其他史', label: '残疾状况', value: '-', name: 'disability' },
            ],
            Environment: [
                { label: '厨房排风设施', value: '', name: 'ventilation' },
                { label: '燃料类型', value: '', name: 'fuel' },
                { label: '饮水', value: '', name: 'water' },
                { label: '厕所', value: '', name: 'toilet' },
                { label: '禽畜栏', value: '', name: 'corral' },
            ],
            Pregnant: [
                { label: '是否怀孕', value: '', name: 'isPregnant' },
                { label: '怀孕日期', value: '', name: 'PregnantDay' },
            ],
            genderFlag: false,
            htmlTitle: '个人信息表',
            labelStyle: {
                // 'width': '180px',
                'color': '#606266'
            },
            infoStyle: {
                'color': '#606266'
            },
            dialogVisible: false,
            file: null, // 存储上传的文件
            BabyInfo: [
                { item: 'general_risk_evaluation', label: 'Admission_Age', name: '年龄', value: '' },
                { item: 'general_risk_evaluation', label: 'Marriage', name: '婚姻状况 (1=单身 / 分居 / 离异 / 丧偶 2=已婚/ 同居 )', value: '' },
                { item: 'general_risk_evaluation', label: 'Height', name: '身高', value: '' },
                { item: 'general_risk_evaluation', label: 'pre_pregnancy_Weight', name: '孕前体重', value: '' },
                { item: 'general_risk_evaluation', label: 'BMI', name: '体重指数（BMI）', value: '' },
                { item: 'general_risk_evaluation', label: 'Smoke_YN', name: '是否吸烟 (0=否 1=是 )', value: '' },
                { item: 'general_risk_evaluation', label: 'Education', name: '接受教育水平 (0=初中及以下教育 1=高中教育 2=大专/本科教育 3=硕士及以上教育 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'Gravida', name: '孕次&nbsp;', value: '' },
                { item: 'zaochanfengxian_history', label: 'Parity_2', name: '产次2', value: '' },
                { item: 'zaochanfengxian_history', label: 'Stillbirth_YN', name: '死胎史 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'Stillbirth_Year', name: '死胎年份', value: '' },
                { item: 'zaochanfengxian_history', label: 'Stillbirth_Month', name: '死胎月份', value: '' },
                { item: 'zaochanfengxian_history', label: 'Stillbirth_REA', name: '死胎原因', value: '' },
                { item: 'zaochanfengxian_history', label: 'NO_AIEP', name: '早孕期流产次数 (1=0 2=1 3=2 4=3 5=4 6=5 7=6 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Year_1', name: '早孕期流产年份_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Month_1', name: '早孕期流产月份_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_NOF_1', name: '早孕期流产胎数_1 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Mode_1', name: '早孕期流产方式_1 (1=自然流产（包括稽留流产、完全流产/不全流产，感染性流产） 2=人工流产 3= 药物流产 4=宫外孕 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Year_2', name: '早孕期流产年份_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Month_2', name: '早孕期流产月份_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_NOF_2', name: '早孕期流产胎数_2 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Mode_2', name: '早孕期流产方式_2 (1=自然流产（包括稽留流产、完全流产/不全流产，感染性流产） 2=人工流产 3= 药物流产 4=宫外孕 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Year_3', name: '早孕期流产年份_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Month_3', name: '早孕期流产月份_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_NOF_3', name: '早孕期流产胎数_3 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Mode_3', name: '早孕期流产方式_3 (1=自然流产（包括稽留流产、完全流产/不全流产，感染性流产） 2=人工流产 3= 药物流产 4=宫外孕 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Year_4', name: '早孕期流产年份_4', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Month_4', name: '早孕期流产月份_4', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_NOF_4', name: '早孕期流产胎数_4 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Mode_4', name: '早孕期流产方式_4 (1=自然流产（包括稽留流产、完全流产/不全流产，感染性流产） 2=人工流产 3= 药物流产 4=宫外孕 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Year_5', name: '早孕期流产年份_5', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Month_5', name: '早孕期流产月份_5', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_NOF_5', name: '早孕期流产胎数_5 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Mode_5', name: '早孕期流产方式_5 (1=自然流产（包括稽留流产、完全流产/不全流产，感染性流产） 2=人工流产 3= 药物流产 4=宫外孕 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Year_6', name: '早孕期流产年份_6', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Month_6', name: '早孕期流产月份_6', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_NOF_6', name: '早孕期流产胎数_6 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'AIEP_Mode_6', name: '早孕期流产方式_6 (1=自然流产（包括稽留流产、完全流产/不全流产，感染性流产） 2=人工流产 3= 药物流产 4=宫外孕 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'NO_MPA', name: '中孕期流产次数 (1=0 2=1 3=2 4=3 5=4 6=5 7=6 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Year_1', name: '中孕期流产年份_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Month_1', name: '中孕期流产月份_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_NOF_1', name: '中孕期流产胎数_1 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Week_1', name: '中孕期流产孕周_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_DAY_1', name: '中孕期流产孕周天数_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Mode_1', name: '中孕期流产方式_1 (1=自发性流产 2=人工流产 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PSP_YN_1', name: '有无无痛羊凸_1 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'NROM_YN_1', name: '有无自然破膜_1 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPAA_REA_1', name: '中孕期人工流产原因_1 (1=患者要求 2=胎儿畸形 3=严重合并症 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MP_SC_1', name: '中孕期严重合并症_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Year_2', name: '中孕期流产年份_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Month_2', name: '中孕期流产月份_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_NOF_2', name: '中孕期流产胎数_2 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Week_2', name: '中孕期流产孕周_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_DAY_2', name: '中孕期流产孕周天数_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Mode_2', name: '中孕期流产方式_2 (1=自发性流产 2=人工流产 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PSP_YN_2', name: '有无无痛羊凸_2 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'NROM_YN_2', name: '有无自然破膜_2 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPAA_REA_2', name: '中孕期人工流产原因_2 (1=患者要求 2=胎儿畸形 3=严重合并症 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MP_SC_2', name: '中孕期严重合并症_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Year_3', name: '中孕期流产年份_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Month_3', name: '中孕期流产月份_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_NOF_3', name: '中孕期流产胎数_3 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Week_3', name: '中孕期流产孕周_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_DAY_3', name: '中孕期流产孕周天数_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Mode_3', name: '中孕期流产方式_3 (1=自发性流产 2=人工流产 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PSP_YN_3', name: '有无无痛羊凸_3 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'NROM_YN_3', name: '有无自然破膜_3 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPAA_REA_3', name: '中孕期人工流产原因_3 (1=患者要求 2=胎儿畸形 3=严重合并症 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MP_SC_3', name: '中孕期严重合并症_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Year_4', name: '中孕期流产年份_4', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Month_4', name: '中孕期流产月份_4', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_NOF_4', name: '中孕期流产胎数_4 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Week_4', name: '中孕期流产孕周_4', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_DAY_4', name: '中孕期流产孕周天数_4', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Mode_4', name: '中孕期流产方式_4 (1=自发性流产 2=人工流产 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PSP_YN_4', name: '有无无痛羊凸_4 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'NROM_YN_4', name: '有无自然破膜_4 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPAA_REA_4', name: '中孕期人工流产原因_4 (1=患者要求 2=胎儿畸形 3=严重合并症 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MP_SC_4', name: '中孕期严重合并症_4', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Year_5', name: '中孕期流产年份_5', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Month_5', name: '中孕期流产月份_5', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_NOF_5', name: '中孕期流产胎数_5 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Week_5', name: '中孕期流产孕周_5', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_DAY_5', name: '中孕期流产孕周天数_5', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Mode_5', name: '中孕期流产方式_5 (1=自发性流产 2=人工流产 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PSP_YN_5', name: '有无无痛羊凸_5 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'NROM_YN_5', name: '有无自然破膜_5 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPAA_REA_5', name: '中孕期人工流产原因_5 (1=患者要求 2=胎儿畸形 3=严重合并症 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MP_SC_5', name: '中孕期严重合并症_5', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Year_6', name: '中孕期流产年份_6', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Month_6', name: '中孕期流产月份_6', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_NOF_6', name: '中孕期流产胎数_6 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Week_6', name: '中孕期流产孕周_6', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_DAY_6', name: '中孕期流产孕周天数_6', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPA_Mode_6', name: '中孕期流产方式_6 (1=自发性流产 2=人工流产 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PSP_YN_6', name: '有无无痛羊凸_6 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'NROM_YN_6', name: '有无自然破膜_6 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MPAA_REA_6', name: '中孕期人工流产原因_6 (1=患者要求 2=胎儿畸形 3=严重合并症 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MP_SC_6', name: '中孕期严重合并症_6', value: '' },
                { item: 'zaochanfengxian_history', label: 'parity_6', name: '产次6 (1=0 2=1 3=2 4=3 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'Parity_NOF_1', name: '生产胎数_1 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_YN_1', name: '是否早产_1 (0=否 1=是 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_Year_1', name: '早产年份_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_Month_1', name: '早产月份_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_Week_1', name: '早产孕周_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_DAY_1', name: '早产孕周天数_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_REA_1', name: '早产原因_1 (1=自发性早产 2=未足月胎膜早破 3=医源性早产 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'LPB_1', name: '医源性早产分类_1 (1=胎儿窘迫 2=子痫前期 3=胎儿生长受限 4=其他 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'OTH_LPB_1', name: '其他医源性早产_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'MOD_1', name: '分娩方式_1 (1=阴道分娩 2=剖宫产分娩 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'Parity_NOF_2', name: '生产胎数_2 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_YN_2', name: '是否早产_2 (0=否 1=是 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_Year_2', name: '早产年份_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_Month_2', name: '早产月份_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_Week_2', name: '早产孕周_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_DAY_2', name: '早产孕周天数_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_REA_2', name: '早产原因_2 (1=自发性早产 2=未足月胎膜早破 3=医源性早产 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'LPB_2', name: '医源性早产分类_2 (1=胎儿窘迫 2=子痫前期 3=胎儿生长受限 4=其他 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'OTH_LPB_2', name: '其他医源性早产_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'MOD_2', name: '分娩方式_2 (1=阴道分娩 2=剖宫产分娩 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'Parity_NOF_3', name: '生产胎数_3 (1=单胎 2=双胎  )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_YN_3', name: '是否早产_3 (0=否 1=是 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_Year_3', name: '早产年份_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_Month_3', name: '早产月份_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_Week_3', name: '早产孕周_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_DAY_3', name: '早产孕周天数_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'PD_REA_3', name: '早产原因_3 (1=自发性早产 2=未足月胎膜早破 3=医源性早产 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'LPB_3', name: '医源性早产分类_3 (1=胎儿窘迫 2=子痫前期 3=胎儿生长受限 4=其他 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'OTH_LPB_3', name: '其他医源性早产_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'MOD_3', name: '分娩方式_3 (1=阴道分娩 2=剖宫产分娩 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PCD_YN', name: '<strong>有无既往慢性疾病</strong> (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'PCD1', name: '<strong>既往慢性疾病</strong> 慢性高血压', value: '' },
                { item: 'zaochanfengxian_history', label: 'PCD2', name: '<strong>既往慢性疾病</strong> 糖尿病', value: '' },
                { item: 'zaochanfengxian_history', label: 'PCD3', name: '<strong>既往慢性疾病</strong> 子痫前期/子痫病史', value: '' },
                { item: 'zaochanfengxian_history', label: 'PCD4', name: '<strong>既往慢性疾病</strong> 肿瘤', value: '' },
                { item: 'zaochanfengxian_history', label: 'PCD5', name: '<strong>既往慢性疾病</strong> 其他', value: '' },
                { item: 'zaochanfengxian_history', label: 'Tumour', name: '肿瘤 (0=甲状腺癌 1=乳腺癌 3=其他 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'OTH_Tumour', name: '其他肿瘤', value: '' },
                { item: 'zaochanfengxian_history', label: 'OTH_PCD', name: '其他既往慢性疾病', value: '' },
                { item: 'zaochanfengxian_history', label: 'HRFRTPD_yn', name: '是否有早产相关高危因素 (0=无 1=有 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'HRFRTPD1', name: '<strong>早产相关高危因素</strong> 宫腔操作史', value: '' },
                { item: 'zaochanfengxian_history', label: 'HRFRTPD2', name: '<strong>早产相关高危因素</strong> 宫颈创伤史或手术史', value: '' },
                { item: 'zaochanfengxian_history', label: 'HRFRTPD3', name: '<strong>早产相关高危因素</strong> PCOS史', value: '' },
                { item: 'zaochanfengxian_history', label: 'HRFRTPD4', name: '<strong>早产相关高危因素</strong> 生殖免疫疾病', value: '' },
                { item: 'zaochanfengxian_history', label: 'HRFRTPD5', name: '<strong>早产相关高危因素</strong> 牙周炎', value: '' },
                { item: 'zaochanfengxian_history', label: 'NO_Hysteroscope', name: '宫腔镜次数', value: '' },
                { item: 'zaochanfengxian_history', label: 'NO_Curettage', name: '清宫次数', value: '' },
                { item: 'zaochanfengxian_history', label: 'HOTCOSType_1', name: '&nbsp;既往宫颈创伤史或手术史分类 LEEP', value: '' },
                { item: 'zaochanfengxian_history', label: 'HOTCOSType_2', name: '&nbsp;既往宫颈创伤史或手术史分类 宫颈锥切术', value: '' },
                { item: 'zaochanfengxian_history', label: 'HOTCOSType_3', name: '&nbsp;既往宫颈创伤史或手术史分类 宫颈环扎手术史', value: '' },
                { item: 'zaochanfengxian_history', label: 'HOTCOSType_4', name: '&nbsp;既往宫颈创伤史或手术史分类 宫颈撕裂史', value: '' },
                { item: 'zaochanfengxian_history', label: 'HOTCOSType_5', name: '&nbsp;既往宫颈创伤史或手术史分类 根治性宫颈切除术史', value: '' },
                { item: 'zaochanfengxian_history', label: 'NO_CC', name: '锥切次数 (1=1 2=2 3=3 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_SI_1', name: '锥切手术指征_1 (1=宫颈糜烂 2=宫颈LSIL 3=宫颈HSIL 4=宫颈原位癌 5=其他 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'OTH_CC_SI_1', name: '其他锥切手术指征_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'CH_1', name: '锥高_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_Diameter_1', name: '锥切直径_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_SI_2', name: '锥切手术指征_2 (1=宫颈糜烂 2=宫颈LSIL 3=宫颈HSIL 4=宫颈原位癌 5=其他 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'OTH_CC_SI_2', name: '其他锥切手术指征_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'CH_2', name: '锥高_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_Diameter_2', name: '锥切直径_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_SI_3', name: '锥切手术指征_3 (1=宫颈糜烂 2=宫颈LSIL 3=宫颈HSIL 4=宫颈原位癌 5=其他 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'OTH_CC_SI_3', name: '其他锥切手术指征_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'CH_3', name: '锥高_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_Diameter_3', name: '锥切直径_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_Time', name: '既往宫颈环扎次数', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_Week_1', name: '既往宫颈环扎孕周_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_DAY_1', name: '既往宫颈环扎孕周天数_1', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_Indications_1', name: '既往宫颈环扎类型_1 (1=以病史为指征 2=以超声为指征 3=以体格检查为指征 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_Type_1', name: '既往宫颈环扎类型_1 (1=Mcdonald（低位） 2=Shirodkar（高位） 3=腹扎 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'RE_YN_1', name: '既往是否重复环扎_1 (1=是 0=否 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_Week_2', name: '既往宫颈环扎孕周_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_DAY_2', name: '既往宫颈环扎孕周天数_2', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_Indications_2', name: '既往宫颈环扎类型_2 (1=以病史为指征 2=以超声为指征 3=以体格检查为指征 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_Type_2', name: '既往宫颈环扎类型_2 (1=Mcdonald（低位） 2=Shirodkar（高位） 3=腹扎 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'RE_YN_2', name: '既往是否重复环扎_2 (1=是 0=否 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_Week_3', name: '既往宫颈环扎孕周_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_DAY_3', name: '既往宫颈环扎孕周天数_3', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_Indications_3', name: '既往宫颈环扎类型_3 (1=以病史为指征 2=以超声为指征 3=以体格检查为指征 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'CC_Type_3', name: '既往宫颈环扎类型_3 (1=Mcdonald（低位） 2=Shirodkar（高位） 3=腹扎 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'RE_YN_3', name: '既往是否重复环扎_3 (1=是 0=否 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'Tear_Cause', name: '撕裂原因 (1=分娩相关 2=宫颈环扎 3=外伤 4=其他 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'OTH_Tear_Cause', name: '其他撕裂原因', value: '' },
                { item: 'zaochanfengxian_history', label: 'PCOSDC_1', name: 'PCOS诊断标准 稀发排卵', value: '' },
                { item: 'zaochanfengxian_history', label: 'PCOSDC_2', name: 'PCOS诊断标准 多囊卵巢', value: '' },
                { item: 'zaochanfengxian_history', label: 'PCOSDC_3', name: 'PCOS诊断标准 高雄激素血症', value: '' },
                { item: 'zaochanfengxian_history', label: 'NMC_YN', name: '既往月经周期是否正常 (1=正常 2=异常 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'MC', name: '既往月经周期 (0=＜3个月 1=3-6个月 3=＞6个月 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'TL', name: '睾酮水平', value: '' },
                { item: 'zaochanfengxian_history', label: 'NTL_YN', name: '睾酮水平是否正常 (1=正常 2=异常 )', value: '' },
                { item: 'zaochanfengxian_history', label: 'AMH', name: '孕前AMH&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;', value: '' },
                { item: 'zaochanfengxian_history', label: 'RIDType_1', name: '生殖免疫疾病种类 APS(抗心磷脂综合征)', value: '' },
                { item: 'zaochanfengxian_history', label: 'RIDType_2', name: '生殖免疫疾病种类 SLE（系统性红斑狼疮）', value: '' },
                { item: 'zaochanfengxian_history', label: 'RIDType_3', name: '生殖免疫疾病种类 细胞免疫缺陷', value: '' },
                { item: 'zaochanfengxian_history', label: 'RIDType_4', name: '生殖免疫疾病种类 高凝状态', value: '' },
                { item: 'zaochanfengxian_history', label: 'RIDType_5', name: '生殖免疫疾病种类 其他', value: '' },
                { item: 'zaochanfengxian_history', label: 'OTH_RID', name: '其他生殖免疫疾病', value: '' },
                { item: 'zaochanfengxian_history', label: 'TR_YN', name: '是否减胎 (1=是 0=否 )', value: '' },
                { item: 'zaochanfengxian_current', label: 'HPI_NOF', name: '现病史胎数 (1=单胎 2=双胎 )', value: '' },
                { item: 'zaochanfengxian_current', label: 'Twins_Type', name: '双胎类型 (1=双绒双羊 2=单绒双羊 3=单绒单羊 )', value: '' },
                { item: 'zaochanfengxian_current', label: 'Pregnancy_Mode', name: '怀孕方式 (1=自然妊娠 2=促排 3=试管 4=人工授精 )', value: '' },
                { item: 'zaochanfengxian_current', label: 'Cell_Type', name: '试管类型 (1=一代 2=二代 3=三代 )', value: '' },
                { item: 'zaochanfengxian_current', label: 'Cell_REA', name: '试管原因 (1=卵巢功能衰竭或无卵巢 2=PCOS 3=输卵管因素 4=男方精子异常 5=原因不明的不孕 6=子宫腺肌症 7=子宫内膜异位症 8=基因疾病 9=其他原因 )', value: '' },
                { item: 'zaochanfengxian_current', label: 'Genetic_Diseases', name: '基因疾病', value: '' },
                { item: 'zaochanfengxian_current', label: 'OTH_REA', name: '其他原因', value: '' },
                { item: 'zaochanfengxian_current', label: 'LM', name: '末次月经', value: '' },
                { item: 'zaochanfengxian_current', label: 'CE_YN', name: '有无宫颈糜烂 (0=无 1=轻度 2=中度 3=重度 )', value: '' },
                { item: 'zaochanfengxian_current', label: 'EPR', name: '早孕反应 (0=无 1=有，不需治疗 2=有，门诊治疗 3=有，住院治疗 )', value: '' },
                { item: 'zaochanfengxian_current', label: 'NT_YN', name: '是否NT (0=否 1=是 )', value: '' },
                { item: 'zaochanfengxian_current', label: 'Amniocentesis_YN', name: '是否羊穿 (0=否 1=是 )', value: '' },
                { item: 'zaochanfengxian_current', label: 'UM', name: '子宫畸形 (1=单角子宫 2=双角子宫 3=纵隔子宫 4=双子宫双宫颈 5=子宫纵隔切除术后 6=其他 )', value: '' },
                { item: 'zaochanfengxian_current', label: 'OTHER_UM', name: '其他子宫畸形', value: '' },
                { item: 'zaochanfengxian_current', label: 'Uterine_Diseases', name: '子宫疾病 (1=子宫肌瘤 2=子宫腺肌症 )', value: '' },
                { item: 'zaochanfengxian_current', label: 'Fibroid', name: '子宫肌瘤大小', value: '' },
                { item: 'zaochanfengxian_current', label: 'Adenomyosis', name: '子宫腺肌症大小', value: '' },
                { item: 'zaochanfengxian_current', label: 'TR_YN', name: '是否减胎 (1=是 0=否 ).1', value: '' },
                { item: 'zaochanfengxian_current', label: 'TR_GW', name: '减胎孕周', value: '' },
                { item: 'zaochanfengxian_current', label: 'TR_GD', name: '减胎孕周天数', value: '' },
                { item: 'zaochanfengxian_current', label: 'CP_YN', name: '是否宫颈息肉摘除 (1=是 0=否 )', value: '' },
                { item: 'zaochanfengxian_current', label: 'CP_GW', name: '息肉摘除孕周', value: '' },
                { item: 'zaochanfengxian_current', label: 'CP_GD', name: '息肉摘除孕周天数', value: '' },
                { item: 'zaochanfengxian_current', label: '3M_STDAT_2', name: '末次月经.1', value: '' },
                { item: 'zaochanfengxian_current', label: '3M_STDAT', name: '末次月经.2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cervical_Cerclage', name: '是否行宫颈环扎 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Number', name: '环扎次数 (1=1次 2=2次 3=3次 4=4次 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Date_1', name: '环扎日期_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Week_1', name: '环扎日期孕周_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Week_Manual_1', name: '环扎日期孕周手动_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_DAY_Manual_1', name: '环扎日期孕周天数手动_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'CDD_Week_1', name: '环扎距分娩孕周时间_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Indication_1', name: '环扎指征_1 (1=病史 2=超声 3=体格检查 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_REA_1', name: '环扎原因_1 (1=一次无痛羊突史 2=三次大月份流产或早产史 3=宫颈手术史+监测发现短宫颈 4=其他 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'OTH_Cerclage_REA_1', name: '其他环扎原因_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'MOCO_1', name: '宫颈内口形态_1 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'diameter_1', name: '直径_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'depth_1', name: '深度_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cervix_Length_1', name: '环扎前宫颈长度_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'ASP_Size_1', name: '羊膜囊凸出大小_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Operation_Type_1', name: '环扎手术类型_1 (1=M 2=S 3=腹扎 4=其他 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'M_1', name: 'M_1 (1=高位 2=低位 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Circumcision_method_1', name: '环扎方式_S1 (1=埋线 2=未埋线 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Abdominal_ligation_1', name: '腹扎_1 (1=孕前 2=孕后 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'OTHER_1', name: '其他环扎手术类型_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Luteohormone_YN_1', name: '环扎术后是否使用黄体酮_1 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Luteohormone_Time_1', name: '术后黄体酮使用时间_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'GH_YN_1', name: '环扎术后是否使用港黄_1 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'GH_Time_1', name: '术后港黄使用时间_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Postoperative_Complication_1', name: '术后并发症_1 (0=无 1=感染（7天内） 2=胎膜早破 3=宫颈撕裂 4=反复阴道出血或大出血（1次出血≥200ml） )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Encircling_Unit_1', name: '环扎单位_1 (1=我院 2=外院 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'OTH_Hospitals_1', name: '其他医院名称_1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Date_2', name: '环扎日期_2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Week_2', name: '环扎日期孕周_2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Week_Manual_2', name: '环扎日期孕周_2.1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_DAY_Manual_2', name: '环扎日期孕周天数手动_2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'CDD_Week_2', name: '环扎距分娩孕周时间_2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Indication_2', name: '环扎指征_2 (1=病史 2=超声 3=体格检查 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_REA_2', name: '环扎原因_2 (1=一次无痛羊突史 2=三次大月份流产或早产史 3=宫颈手术史+监测发现短宫颈 4=其他 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'OTH_Cerclage_REA_2', name: '其他环扎原因_2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'MOCO_2', name: '宫颈内口形态_2 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'diameter_2', name: '直径_2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'depth_2', name: '深度_2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cervix_Length_2', name: '环扎前宫颈长度_2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'ASP_Size_2', name: '羊膜囊凸出大小_2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'cerclage_shift_2', name: '原有环扎线移位-2 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'cerclage_remove_2', name: '本次是否拆除既往环扎线-2 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Operation_Type_2', name: '环扎手术类型_2 (1=M 2=S 3=腹扎 4=其他 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'M_2', name: 'M_2 (1=高位 2=低位 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Circumcision_method_2', name: '环扎方式_S2 (1=埋线 2=未埋线 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Abdominal_ligation_2', name: '腹扎_S2 (1=孕前 2=孕后 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'OTHCOT_2', name: '其他环扎手术类型_2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Luteohormone_YN_2', name: '环扎术后是否使用黄体酮_2 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Luteohormone_Time_2', name: '术后黄体酮使用时间_2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'GH_YN_2', name: '环扎术后是否使用港黄_2 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'GH_Time_2', name: '术后港黄使用时间_2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Postoperative_Complication_2', name: '术后并发症_2 (0=无 1=感染（7天内） 2=胎膜早破 3=宫颈撕裂 4=反复阴道出血或大出血（1次出血≥200ml） )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Encircling_Unit_2', name: '环扎单位_2 (1=我院 2=外院 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'OTH_Hospitals_2', name: '其他医院名称_2', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Date_3', name: '环扎日期_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Week_3', name: '环扎日期孕周_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Week_Manual_3', name: '环扎日期孕周手动_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_DAY_Manual_3', name: '环扎日期孕周天数手动_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'CDD_Week_3', name: '环扎距分娩孕周时间_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Indication_3', name: '环扎指征_3 (1=病史 2=超声 3=体格检查 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_REA_3', name: '环扎原因_3 (1=一次无痛羊突史 2=三次大月份流产或早产史 3=宫颈手术史+监测发现短宫颈 4=其他 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'OTH_Cerclage_REA_3', name: '其他环扎原因_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'MOCO_3', name: '宫颈内口形态_3 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'diameter_3', name: '直径_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'depth_3', name: '深度_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cervix_Length_3', name: '环扎前宫颈长度_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'ASP_Size_3', name: '羊膜囊凸出大小_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'cerclage_shift_3', name: '原有环扎线移位_3 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'cerclage_remove_3', name: '本次是否拆除既往环扎线_3 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Operation_Type_3', name: '环扎手术类型_3 (1=M 2=S 3=腹扎 4=其他 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'M_3', name: 'M_3 (1=高位 2=低位 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Circumcision_method_S3', name: '环扎方式_S3 (1=埋线 2=未埋线 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Abdominal_ligation_S3', name: '腹扎_S3 (1=孕前 2=孕后 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'OTHCOT_3', name: '其他环扎手术类型_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Luteohormone_YN_3', name: '环扎术后是否使用黄体酮_3 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Luteohormone_Time_3', name: '术后黄体酮使用时间_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'GH_YN_3', name: '环扎术后是否使用港黄_3 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'GH_Time_3', name: '术后港黄使用时间_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Postoperative_Complication_3', name: '术后并发症_3 (0=无 1=感染（7天内） 2=胎膜早破 3=宫颈撕裂 4=反复阴道出血或大出血（1次出血≥200ml） )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Encircling_Unit_3', name: '环扎单位_3 (1=我院 2=外院 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'OTH_Hospitals_3', name: '其他医院名称_3', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Date_4', name: '环扎日期_4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Week_4', name: '环扎日期孕周_4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Week_Manual_4', name: '环扎日期孕周手动_4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_DAY_Manual_4', name: '环扎日期孕周天数手动_4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'CDD_Week_4', name: '环扎距分娩孕周时间_4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Indication_4', name: '环扎指征_4 (1=病史 2=超声 3=体格检查 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_REA_4', name: '环扎原因_4 (1=一次无痛羊突史 2=三次大月份流产或早产史 3=宫颈手术史+监测发现短宫颈 4=其他 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'OTH_Cerclage_REA_4', name: '其他环扎原因_4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'MOCO_4', name: '宫颈内口形态_4 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'diameter_4', name: '直径_4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'depth_4', name: '深度_4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cervix_Length_4', name: '环扎前宫颈长度_4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'ASP_Size_4', name: '羊膜囊凸出大小_4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'cerclage_shift_4', name: '原有环扎线移位_4 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'cerclage_remove_4', name: '本次是否拆除既往环扎线_4 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Cerclage_Operation_Type_4', name: '环扎手术类型_4 (1=M 2=S 3=腹扎 4=其他 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'M_4', name: 'M_4 (1=高位 2=低位 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Circumcision_method_S4', name: '环扎方式_S4 (1=埋线 2=未埋线 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Abdominal_ligation_S4', name: '腹扎_4 (1=孕前 2=孕后 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'OTHCOT_3_2', name: '其他环扎手术类型_3.1', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Luteohormone_YN_4', name: '环扎术后是否使用黄体酮_4 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Luteohormone_Time_4', name: '术后黄体酮使用时间_4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'GH_YN_4', name: '环扎术后是否使用港黄_4 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'GH_Time_4', name: '术后港黄使用时间_4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Postoperative_Complication_4', name: '术后并发症_34 (0=无 1=感染（7天内） 2=胎膜早破 3=宫颈撕裂 4=反复阴道出血或大出血（1次出血≥200ml） )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Encircling_Unit_4', name: '环扎单位_34 (1=我院 2=外院 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'OTH_Hospitals_4', name: '其他医院名称_4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'CCR_Week', name: '宫颈环扎线拆除孕周-周', value: '' },
                { item: 'zhuyuan_huanzha', label: 'CCR_Day4', name: '宫颈环扎线拆除孕周-天4', value: '' },
                { item: 'zhuyuan_huanzha', label: 'Wire_Removal_REA', name: '拆线原因 (1=择期拆线 2=早产临产/临产 3=胎膜早破 4=宫内感染 5=宫颈撕伤 6=子宫破裂 7=其他 )', value: '' },
                { item: 'zhuyuan_huanzha', label: 'OTHERR', name: '其他拆线原因', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Tyre_Reduction_YN_2', name: '有无母体孕期并发症 (0=无 1=有 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP1', name: '母体孕期并发症 妊娠期高血压', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP2', name: '母体孕期并发症 子痫前期/子痫', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP3', name: '母体孕期并发症 慢性高血压合并子痫', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP4', name: '母体孕期并发症 妊娠期肝内胆汁淤积', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP5', name: '母体孕期并发症 孕期感染', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP6', name: '母体孕期并发症 糖尿病合并妊娠', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP7', name: '母体孕期并发症 GDM A1', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP8', name: '母体孕期并发症 GDM A2', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP9', name: '母体孕期并发症 甲减', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP10', name: '母体孕期并发症 甲亢', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP11', name: '母体孕期并发症 亚临床甲减', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP12', name: '母体孕期并发症 亚临床甲亢', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP13', name: '母体孕期并发症 其他', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP14', name: '母体孕期并发症 妊娠合并多囊卵巢', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP15', name: '母体孕期并发症 妊娠合并肥胖', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP16', name: '母体孕期并发症 妊娠合并子宫肌瘤', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MCDP17', name: '母体孕期并发症 妊娠合并胰岛素抵抗', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'OTH_MCDP', name: '其他母体孕期并发症', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Tyre_Reduction_YN', name: '是否减胎 (0=否 1=是 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Tyre_Reduction', name: '减胎情况 (1=四胎减成2胎 2=三胎减成2胎 3=三胎减成1胎 4=二胎减成1胎 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Tyre_Reduction_Week', name: '减胎孕周-周', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Tyre_Reduction_day', name: '减胎孕周-天', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Fetal_arrest_YN', name: '双胎之一胎停 (0=否 1=是 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Fetal_arrest_Gestational_Week', name: '胎停孕周-周', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Fetal_arrest_Gestational_day', name: '胎停孕周-天', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'PS', name: '孕期状态 (1=正常活动 2=卧床 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'PROM_YN', name: '是否胎膜早破 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'PROM_Week', name: '胎膜早破孕周', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'PROM_DAY', name: '胎膜早破孕周天数', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Luteohormone_YN', name: '环扎术后是否使用黄体酮 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Luteohormone_Time', name: '环扎术后黄体酮使用时间', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'GH_YN', name: '环扎术后是否使用港黄 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'GH_Time', name: '环扎术后港黄使用时间', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Hypertension', name: '高血压 (1=重度高血压 2=子痫前期（轻度) 3=子痫前期（重度) 4=子痫 5=HELLP )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Cervical_Laceration', name: '宫颈撕裂 (1=环扎所致宫颈裂伤 2=分娩所致宫颈裂伤 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'CC_YN', name: '是否有临床绒毛膜羊膜炎 (0=否 1=是 )', value: '' },
                { item: 'gongjingjiance', label: '12_CM_Time_1', name: '孕12周前宫颈监测时间_1', value: '' },
                { item: 'gongjingjiance', label: '12_CM_Week_1', name: '孕12周前宫颈监测孕周_1', value: '' },
                { item: 'gongjingjiance', label: '12UMDOC_YN_1_1', name: '孕12周前超声监测宫颈口有无扩张_1 无', value: '' },
                { item: 'gongjingjiance', label: '12UMDOC_YN_1_2', name: '孕12周前超声监测宫颈口有无扩张_1 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '12UMDOC_YN_1_3', name: '孕12周前超声监测宫颈口有无扩张_1 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '12_MOCO_1', name: '孕12周前宫颈内口形态_1 (0=T 1=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '12_diameter_1', name: '孕12周前直径_1', value: '' },
                { item: 'gongjingjiance', label: '12_depth_1', name: '孕12周前深度_1', value: '' },
                { item: 'gongjingjiance', label: '12_ELOC_1', name: '孕12周前宫颈有效长度_1', value: '' },
                { item: 'gongjingjiance', label: '12_HP_YN_1', name: '孕12周前是否存在高回声沉淀_1 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '12_VEDOC_YN_1', name: '孕12周前宫口扩张_1 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '12_EL_1', name: '孕12周前宫颈外口扩张大小_1', value: '' },
                { item: 'gongjingjiance', label: '12cerclage_Cervical_length_1', name: '环扎线下宫颈长度_1', value: '' },
                { item: 'gongjingjiance', label: '12_CM_Time_2', name: '孕12周前宫颈监测时间_2', value: '' },
                { item: 'gongjingjiance', label: '12_CM_Week_2', name: '孕12周前宫颈监测孕周_2', value: '' },
                { item: 'gongjingjiance', label: '12UMDOC_YN_2_1', name: '孕12周前超声监测宫颈口有无扩张_2 无', value: '' },
                { item: 'gongjingjiance', label: '12UMDOC_YN_2_2', name: '孕12周前超声监测宫颈口有无扩张_2 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '12UMDOC_YN_2_3', name: '孕12周前超声监测宫颈口有无扩张_2 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '12_MOCO_2', name: '孕12周前宫颈内口形态_2 (0=T 1=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '12_diameter_2', name: '孕12周前直径_2', value: '' },
                { item: 'gongjingjiance', label: '12_depth_2', name: '孕12周前深度_2', value: '' },
                { item: 'gongjingjiance', label: '12_ELOC_2', name: '孕12周前宫颈有效长度_2', value: '' },
                { item: 'gongjingjiance', label: '12_HP_YN_2', name: '孕12周前是否存在高回声沉淀_2 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '12_VEDOC_YN_2', name: '孕12周前阴道检查宫口有无扩张_2 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '12_EL_2', name: '孕12周前宫颈外口扩张大小_2', value: '' },
                { item: 'gongjingjiance', label: '12cerclage_Cervical_length_2', name: '环扎线下宫颈长度_2', value: '' },
                { item: 'gongjingjiance', label: '12_CM_Time_3', name: '孕12周前宫颈监测时间_3', value: '' },
                { item: 'gongjingjiance', label: '12_CM_Week_3', name: '孕12周前宫颈监测孕周_3', value: '' },
                { item: 'gongjingjiance', label: '12UMDOC_YN_3_1', name: '孕12周前超声监测宫颈口有无扩张_3 无', value: '' },
                { item: 'gongjingjiance', label: '12UMDOC_YN_3_2', name: '孕12周前超声监测宫颈口有无扩张_3 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '12UMDOC_YN_3_3', name: '孕12周前超声监测宫颈口有无扩张_3 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '12_MOCO_3', name: '孕12周前宫颈内口形态_3 (0=T 1=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '12_diameter_3', name: '孕12周前直径_3', value: '' },
                { item: 'gongjingjiance', label: '12_depth_3', name: '孕12周前深度_3', value: '' },
                { item: 'gongjingjiance', label: '12_ELOC_3', name: '孕12周前宫颈有效长度_3', value: '' },
                { item: 'gongjingjiance', label: '12_HP_YN_3', name: '孕12周前是否存在高回声沉淀_3 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '12_EL_3', name: '孕12周前宫颈外口扩张大小_3', value: '' },
                { item: 'gongjingjiance', label: '12_VEDOC_YN_3', name: '孕12周前阴道检查宫口有无扩张_3 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '12cerclage_Cervical_length_3', name: '环扎线下宫颈长度_3', value: '' },
                { item: 'gongjingjiance', label: '1216_CM_Time_1', name: '12-16周宫颈监测时间_1', value: '' },
                { item: 'gongjingjiance', label: '1216_CM_Week_1', name: '12-16周宫颈监测孕周_1', value: '' },
                { item: 'gongjingjiance', label: '1216UMDOC_YN_1_1', name: '12-16周超声监测宫颈口有无扩张_1 无', value: '' },
                { item: 'gongjingjiance', label: '1216UMDOC_YN_1_2', name: '12-16周超声监测宫颈口有无扩张_1 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '1216UMDOC_YN_1_3', name: '12-16周超声监测宫颈口有无扩张_1 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '1216_MOCO_1', name: '12-16周宫颈内口形态_1 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '1216_ diameter_1', name: '12-16周直径_1', value: '' },
                { item: 'gongjingjiance', label: '1216_depth_1', name: '12-16周深度_1', value: '' },
                { item: 'gongjingjiance', label: '1216_ELOC_1', name: '12-16周宫颈有效长度_1', value: '' },
                { item: 'gongjingjiance', label: '1216_HP_YN_1', name: '12-16周是否存在高回声沉淀_1 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '1216_VEDOC_YN_1', name: '12-16周阴道检查宫口有无扩张_1 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '1216_EL_1', name: '12-16周宫颈外口扩张大小_1', value: '' },
                { item: 'gongjingjiance', label: '1216_cerclage_Cervical_length_1', name: '12-16周环扎线下宫颈长度_1', value: '' },
                { item: 'gongjingjiance', label: 'ADD1', name: '添加按钮1 (1=<span style="font-size:20px;">点击添加+</span> )', value: '' },
                { item: 'gongjingjiance', label: '1216_CM_Time_2', name: '12-16周宫颈监测时间_2', value: '' },
                { item: 'gongjingjiance', label: '1216_CM_Week_2', name: '12-16周宫颈监测孕周_2', value: '' },
                { item: 'gongjingjiance', label: '1216UMDOC_YN_2_1', name: '12-16周超声监测宫颈口有无扩张_2 无', value: '' },
                { item: 'gongjingjiance', label: '1216UMDOC_YN_2_2', name: '12-16周超声监测宫颈口有无扩张_2 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '1216UMDOC_YN_2_3', name: '12-16周超声监测宫颈口有无扩张_2 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '1216_MOCO_2', name: '12-16周宫颈内口形态_2 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '1216_ diameter_2', name: '12-16周直径_2', value: '' },
                { item: 'gongjingjiance', label: '1216_depth_2', name: '12-16周深度_2', value: '' },
                { item: 'gongjingjiance', label: '1216_ELOC_2', name: '12-16周宫颈有效长度_2', value: '' },
                { item: 'gongjingjiance', label: '1216_HP_YN_2', name: '12-16周是否存在高回声沉淀_2 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '1216_VEDOC_YN_2', name: '12-16周阴道检查宫口有无扩张_2 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '1216_EL_2', name: '12-16周宫颈外口扩张大小_2', value: '' },
                { item: 'gongjingjiance', label: '1216_cerclage_Cervical_length_2', name: '12-16周环扎线下宫颈长度_2', value: '' },
                { item: 'gongjingjiance', label: 'ADD2', name: '添加按钮2 (1=<span style="font-size:20px;">点击添加+</span> )', value: '' },
                { item: 'gongjingjiance', label: '1216_CM_Time_3', name: '12-16周宫颈监测时间_3', value: '' },
                { item: 'gongjingjiance', label: '1216_CM_Week_3', name: '12-16周宫颈监测孕周_3', value: '' },
                { item: 'gongjingjiance', label: '1216UMDOC_YN_3_1', name: '12-16周超声监测宫颈口有无扩张_3 无', value: '' },
                { item: 'gongjingjiance', label: '1216UMDOC_YN_3_2', name: '12-16周超声监测宫颈口有无扩张_3 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '1216UMDOC_YN_3_3', name: '12-16周超声监测宫颈口有无扩张_3 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '1216_MOCO_3', name: '12-16周宫颈内口形态_3 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '1216_ diameter_3', name: '12-16周直径_3', value: '' },
                { item: 'gongjingjiance', label: '1216_depth_3', name: '12-16周深度_3', value: '' },
                { item: 'gongjingjiance', label: '1216_ELOC_3', name: '12-16周宫颈有效长度_3', value: '' },
                { item: 'gongjingjiance', label: '1216_HP_YN_3', name: '12-16周是否存在高回声沉淀_3 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '1216_VEDOC_YN_3', name: '12-16周阴道检查宫口有无扩张_3 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '1216_EL_3', name: '12-16周宫颈外口扩张大小_3', value: '' },
                { item: 'gongjingjiance', label: '1216_cerclage_Cervical_length_3', name: '12-16周环扎线下宫颈长度_3', value: '' },
                { item: 'gongjingjiance', label: '1620_CM_Time_1', name: '16-20周宫颈监测时间_1', value: '' },
                { item: 'gongjingjiance', label: '1620_CM_Week_1', name: '16-20周宫颈监测孕周_1', value: '' },
                { item: 'gongjingjiance', label: '1620UMDOC_YN_1_1', name: '16-20周超声监测宫颈口有无扩张_1 无', value: '' },
                { item: 'gongjingjiance', label: '1620UMDOC_YN_1_2', name: '16-20周超声监测宫颈口有无扩张_1 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '1620UMDOC_YN_1_3', name: '16-20周超声监测宫颈口有无扩张_1 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '1620_MOCO_1', name: '16-20周宫颈内口形态_1 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '1620_diameter_1', name: '16-20周直径_1', value: '' },
                { item: 'gongjingjiance', label: '1620_depth_1', name: '16-20周深度_1', value: '' },
                { item: 'gongjingjiance', label: '1620_ELOC_1', name: '16-20周宫颈有效长度_1', value: '' },
                { item: 'gongjingjiance', label: '1620_HP_YN_1', name: '16-20周是否存在高回声沉淀_1 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '1620_VEDOC_YN_1', name: '16-20周阴道检查宫口有无扩张_1 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '1620_EL_1', name: '16-20周宫颈外口扩张大小_1', value: '' },
                { item: 'gongjingjiance', label: '1620_cerclage_Cervical_length_1', name: '16-20环扎线下宫颈长度_1', value: '' },
                { item: 'gongjingjiance', label: 'ADD3', name: '添加按钮3 (1=<span style="font-size:20px;">点击添加+</span> )', value: '' },
                { item: 'gongjingjiance', label: '1620_CM_Time_2', name: '16-20周宫颈监测时间_2', value: '' },
                { item: 'gongjingjiance', label: '1620_CM_Week_2', name: '16-20周宫颈监测孕周_2', value: '' },
                { item: 'gongjingjiance', label: '1620UMDOC_YN_2_1', name: '16-20周超声监测宫颈口有无扩张_2 无', value: '' },
                { item: 'gongjingjiance', label: '1620UMDOC_YN_2_2', name: '16-20周超声监测宫颈口有无扩张_2 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '1620UMDOC_YN_2_3', name: '16-20周超声监测宫颈口有无扩张_2 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '1620_MOCO_2', name: '16-20周宫颈内口形态_2 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '1620_diameter_2', name: '16-20周直径_2', value: '' },
                { item: 'gongjingjiance', label: '1620_depth_2', name: '16-20周深度_2', value: '' },
                { item: 'gongjingjiance', label: '1620_ELOC_2', name: '16-20周宫颈有效长度_2', value: '' },
                { item: 'gongjingjiance', label: '1620_HP_YN_2', name: '16-20周是否存在高回声沉淀_2 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '1620_VEDOC_YN_2', name: '16-20周阴道检查宫口有无扩张_2 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '1620_EL_2', name: '16-20周宫颈外口扩张大小_2', value: '' },
                { item: 'gongjingjiance', label: '1620_cerclage_Cervical_length_2', name: '16-20环扎线下宫颈长度_2', value: '' },
                { item: 'gongjingjiance', label: 'ADD4', name: '添加按钮4 (1=<span style="font-size:20px;">点击添加+</span> )', value: '' },
                { item: 'gongjingjiance', label: '1620_CM_Time_3', name: '16-20周宫颈监测时间_3', value: '' },
                { item: 'gongjingjiance', label: '1620_CM_Week_3', name: '16-20周宫颈监测孕周_3', value: '' },
                { item: 'gongjingjiance', label: '1620UMDOC_YN_3_1', name: '16-20周超声监测宫颈口有无扩张_3 无', value: '' },
                { item: 'gongjingjiance', label: '1620UMDOC_YN_3_2', name: '16-20周超声监测宫颈口有无扩张_3 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '1620UMDOC_YN_3_3', name: '16-20周超声监测宫颈口有无扩张_3 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '1620_MOCO_3', name: '16-20周宫颈内口形态_3 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '1620_diameter_3', name: '16-20周直径_3', value: '' },
                { item: 'gongjingjiance', label: '1620_depth_3', name: '16-20周深度_3', value: '' },
                { item: 'gongjingjiance', label: '1620_ELOC_3', name: '16-20周宫颈有效长度_3', value: '' },
                { item: 'gongjingjiance', label: '1620_HP_YN_3', name: '16-20周是否存在高回声沉淀_3 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '1620_VEDOC_YN_3', name: '16-20周阴道检查宫口有无扩张_3 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '1620_EL_3', name: '16-20周宫颈外口扩张大小_3', value: '' },
                { item: 'gongjingjiance', label: '1620_cerclage_Cervical_length_3', name: '16-20环扎线下宫颈长度_3', value: '' },
                { item: 'gongjingjiance', label: '2024_CM_Time_1', name: '20-24周宫颈监测时间_1', value: '' },
                { item: 'gongjingjiance', label: '2024_CM_Week_1', name: '20-24周宫颈监测孕周_1', value: '' },
                { item: 'gongjingjiance', label: '2024UMDOC_YN_1_1', name: '20-24周超声监测宫颈口有无扩张_1 无', value: '' },
                { item: 'gongjingjiance', label: '2024UMDOC_YN_1_2', name: '20-24周超声监测宫颈口有无扩张_1 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '2024UMDOC_YN_1_3', name: '20-24周超声监测宫颈口有无扩张_1 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '2024_MOCO_1', name: '20-24周宫颈内口形态_1 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '2024_diameter_1', name: '20-24周直径_1', value: '' },
                { item: 'gongjingjiance', label: '2024_depth_1', name: '20-24周深度_1', value: '' },
                { item: 'gongjingjiance', label: '2024_ELOC_1', name: '20-24周宫颈有效长度_1', value: '' },
                { item: 'gongjingjiance', label: '2024_HP_YN_1', name: '20-24周是否存在高回声沉淀_1 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2024_VEDOC_YN_1', name: '20-24周阴道检查宫口有无扩张_1 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2024_EL_1', name: '20-24周宫颈外口扩张大小_1', value: '' },
                { item: 'gongjingjiance', label: '2024_cerclage_Cervical_length_1', name: '20-24周环扎线下宫颈长度_1', value: '' },
                { item: 'gongjingjiance', label: 'ADD5', name: '添加按钮5 (1=<span style="font-size:20px;">点击添加+</span> )', value: '' },
                { item: 'gongjingjiance', label: '2024_CM_Time_2', name: '20-24周宫颈监测时间_2', value: '' },
                { item: 'gongjingjiance', label: '2024_CM_Week_2', name: '20-24周宫颈监测孕周_2', value: '' },
                { item: 'gongjingjiance', label: '2024UMDOC_YN_2_1', name: '20-24周超声监测宫颈口有无扩张_2 无', value: '' },
                { item: 'gongjingjiance', label: '2024UMDOC_YN_2_2', name: '20-24周超声监测宫颈口有无扩张_2 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '2024UMDOC_YN_2_3', name: '20-24周超声监测宫颈口有无扩张_2 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '2024_MOCO_2', name: '20-24周宫颈内口形态_2 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '2024_diameter_2', name: '20-24周直径_2', value: '' },
                { item: 'gongjingjiance', label: '2024_depth_2', name: '20-24周深度_2', value: '' },
                { item: 'gongjingjiance', label: '2024_ELOC_2', name: '20-24周宫颈有效长度_2', value: '' },
                { item: 'gongjingjiance', label: '2024_HP_YN_2', name: '20-24周是否存在高回声沉淀_2 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2024_VEDOC_YN_2', name: '20-24周阴道检查宫口有无扩张_2 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2024_EL_2', name: '20-24周宫颈外口扩张大小_2', value: '' },
                { item: 'gongjingjiance', label: '2024_cerclage_Cervical_length_2', name: '20-24周环扎线下宫颈长度_2', value: '' },
                { item: 'gongjingjiance', label: 'ADD6', name: '添加按钮6 (1=<span style="font-size:20px;">点击添加+</span> )', value: '' },
                { item: 'gongjingjiance', label: '2024_CM_Time_3', name: '20-24周宫颈监测时间_3', value: '' },
                { item: 'gongjingjiance', label: '2024_CM_Week_3', name: '20-24周宫颈监测孕周_3', value: '' },
                { item: 'gongjingjiance', label: '2024UMDOC_YN_3_1', name: '20-24周超声监测宫颈口有无扩张_3 无', value: '' },
                { item: 'gongjingjiance', label: '2024UMDOC_YN_3_2', name: '20-24周超声监测宫颈口有无扩张_3 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '2024UMDOC_YN_3_3', name: '20-24周超声监测宫颈口有无扩张_3 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '2024_MOCO_3', name: '20-24周宫颈内口形态_3 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '2024_diameter_3', name: '20-24周直径_3', value: '' },
                { item: 'gongjingjiance', label: '2024_depth_3', name: '20-24周深度_3', value: '' },
                { item: 'gongjingjiance', label: '2024_ELOC_3', name: '20-24周宫颈有效长度_3', value: '' },
                { item: 'gongjingjiance', label: '2024_HP_YN_3', name: '20-24周是否存在高回声沉淀_3 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2024_EL_3', name: '20-24周宫颈外口扩张大小_3', value: '' },
                { item: 'gongjingjiance', label: '2024_VEDOC_YN_3', name: '20-24周阴道检查宫口有无扩张_3 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2024_cerclage_Cervical_length_3', name: '20-24周环扎线下宫颈长度_3', value: '' },
                { item: 'gongjingjiance', label: '2428_CM_Time_1', name: '24-28周监测时间_1', value: '' },
                { item: 'gongjingjiance', label: '2428_CM_Week_1', name: '24-28周宫颈监测孕周_1', value: '' },
                { item: 'gongjingjiance', label: '2428UMDOC_YN_1_1', name: '24-28周超声监测宫颈口有无扩张_1 无', value: '' },
                { item: 'gongjingjiance', label: '2428UMDOC_YN_1_2', name: '24-28周超声监测宫颈口有无扩张_1 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '2428UMDOC_YN_1_3', name: '24-28周超声监测宫颈口有无扩张_1 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '2428_MOCO_1', name: '24-28周宫颈内口形态_1 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '2428_diameter_1', name: '24-28周直径_1', value: '' },
                { item: 'gongjingjiance', label: '2428_depth_1', name: '24-28周深度_1', value: '' },
                { item: 'gongjingjiance', label: '2428_ELOC_1', name: '24-28周宫颈有效长度_1', value: '' },
                { item: 'gongjingjiance', label: '2428_HP_YN_1', name: '24-28周是否存在高回声沉淀_1 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2428_VEDOC_YN_1', name: '24-28周阴道检查宫口有无扩张_1 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2428_EL_1', name: '24-28周宫颈外口扩张大小_1', value: '' },
                { item: 'gongjingjiance', label: '2428_cerclage_Cervical_length_1', name: '24-28周环扎线下宫颈长度_1', value: '' },
                { item: 'gongjingjiance', label: 'ADD7', name: '添加按钮7 (1=<span style="font-size:20px;">点击添加+</span> )', value: '' },
                { item: 'gongjingjiance', label: '2428_CM_Time_2', name: '24-28周监测时间_2', value: '' },
                { item: 'gongjingjiance', label: '2428_CM_Week_2', name: '24-28周宫颈监测孕周_2', value: '' },
                { item: 'gongjingjiance', label: '2428UMDOC_YN_2_1', name: '24-28周超声监测宫颈口有无扩张_2 无', value: '' },
                { item: 'gongjingjiance', label: '2428UMDOC_YN_2_2', name: '24-28周超声监测宫颈口有无扩张_2 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '2428UMDOC_YN_2_3', name: '24-28周超声监测宫颈口有无扩张_2 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '2428_MOCO_2', name: '24-28周宫颈内口形态_2 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '2428_diameter_2', name: '24-28周直径_2', value: '' },
                { item: 'gongjingjiance', label: '2428_depth_2', name: '24-28周深度_2', value: '' },
                { item: 'gongjingjiance', label: '2428_ELOC_2', name: '24-28周宫颈有效长度_2', value: '' },
                { item: 'gongjingjiance', label: '2428_HP_YN_2', name: '24-28周是否存在高回声沉淀_2 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2428_VEDOC_YN_2', name: '24-28周阴道检查宫口有无扩张_2 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2428_EL_2', name: '24-28周宫颈外口扩张大小_2', value: '' },
                { item: 'gongjingjiance', label: '2428_cerclage_Cervical_length_2', name: '24-28周环扎线下宫颈长度_2', value: '' },
                { item: 'gongjingjiance', label: 'ADD8', name: '添加按钮8 (1=<span style="font-size:20px;">点击添加+</span> )', value: '' },
                { item: 'gongjingjiance', label: '2428_CM_Time_3', name: '24-28周监测时间_3', value: '' },
                { item: 'gongjingjiance', label: '2428_CM_Week_3', name: '24-28周宫颈监测孕周_3', value: '' },
                { item: 'gongjingjiance', label: '2428UMDOC_YN_3_1', name: '24-28周超声监测宫颈口有无扩张_3 无', value: '' },
                { item: 'gongjingjiance', label: '2428UMDOC_YN_3_2', name: '24-28周超声监测宫颈口有无扩张_3 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '2428UMDOC_YN_3_3', name: '24-28周超声监测宫颈口有无扩张_3 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '2428_MOCO_3', name: '24-28周宫颈内口形态_3 (1=T 2=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '2428_diameter_3', name: '24-28周直径_3', value: '' },
                { item: 'gongjingjiance', label: '2428_depth_3', name: '24-28周深度_3', value: '' },
                { item: 'gongjingjiance', label: '2428_ELOC_3', name: '24-28周宫颈有效长度_3', value: '' },
                { item: 'gongjingjiance', label: '2428_HP_YN_3', name: '24-28周是否存在高回声沉淀_3 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2428_VEDOC_YN_3', name: '24-28周阴道检查宫口有无扩张_3 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2428_EL_3', name: '24-28周宫颈外口扩张大小_3', value: '' },
                { item: 'gongjingjiance', label: '2428_cerclage_Cervical_length_3', name: '24-28周环扎线下宫颈长度_3', value: '' },
                { item: 'gongjingjiance', label: '2832_CM_Time_1', name: '28-32周宫颈监测时间_1', value: '' },
                { item: 'gongjingjiance', label: '2832_CM_Week_1', name: '28-32周宫颈监测孕周_1', value: '' },
                { item: 'gongjingjiance', label: '2832UMDOC_YN_1_1', name: '28-32周超声监测宫颈口有无扩张_1 无', value: '' },
                { item: 'gongjingjiance', label: '2832UMDOC_YN_1_2', name: '28-32周超声监测宫颈口有无扩张_1 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '2832UMDOC_YN_1_3', name: '28-32周超声监测宫颈口有无扩张_1 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '2832_MOCO_1', name: '28-32宫颈内口形态_1 (0=T 1=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '2832_diameter_1', name: '28-32直径_1', value: '' },
                { item: 'gongjingjiance', label: '2832_depth_1', name: '28-32深度_1', value: '' },
                { item: 'gongjingjiance', label: '2832_ELOC_1', name: '28-32周宫颈有效长度_1', value: '' },
                { item: 'gongjingjiance', label: '2832_HP_YN_1', name: '28-32周是否存在高回声沉淀_1 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2832_VEDOC_YN_1', name: '28-32周阴道检查宫口有无扩张_1 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2832_EL_1', name: '28-32周宫颈外口扩张大小_1', value: '' },
                { item: 'gongjingjiance', label: '2832_cerclage_Cervical_length_1', name: '28-32环扎线下宫颈长度_1', value: '' },
                { item: 'gongjingjiance', label: 'ADD9', name: '添加按钮9 (1=<span style="font-size:20px;">点击添加+</span> )', value: '' },
                { item: 'gongjingjiance', label: '2832_CM_Time_2', name: '28-32周宫颈监测时间_2', value: '' },
                { item: 'gongjingjiance', label: '2832_CM_Week_2', name: '28-32周宫颈监测孕周_2', value: '' },
                { item: 'gongjingjiance', label: '2832UMDOC_YN_2_1', name: '28-32周超声监测宫颈口有无扩张_2 无', value: '' },
                { item: 'gongjingjiance', label: '2832UMDOC_YN_2_2', name: '28-32周超声监测宫颈口有无扩张_2 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '2832UMDOC_YN_2_3', name: '28-32周超声监测宫颈口有无扩张_2 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '2832_MOCO_2', name: '28-32宫颈内口形态_2 (0=T 1=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '2832_diameter_2', name: '28-32直径_2', value: '' },
                { item: 'gongjingjiance', label: '2832_depth_2', name: '28-32深度_2', value: '' },
                { item: 'gongjingjiance', label: '2832_ELOC_2', name: '28-32周宫颈有效长度_2', value: '' },
                { item: 'gongjingjiance', label: '2832_HP_YN_2', name: '28-32周是否存在高回声沉淀_2 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2832_VEDOC_YN_2', name: '28-32周阴道检查宫口有无扩张_2 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2832_EL_2', name: '28-32周宫颈外口扩张大小_2', value: '' },
                { item: 'gongjingjiance', label: '2832_cerclage_Cervical_length_2', name: '28-32环扎线下宫颈长度_2', value: '' },
                { item: 'gongjingjiance', label: 'ADD10', name: '添加按钮10 (1=<span style="font-size:20px;">点击添加+</span> )', value: '' },
                { item: 'gongjingjiance', label: '2832_CM_Time_3', name: '28-32周宫颈监测时间_3', value: '' },
                { item: 'gongjingjiance', label: '2832_CM_Week_3', name: '28-32周宫颈监测孕周_3', value: '' },
                { item: 'gongjingjiance', label: '2832UMDOC_YN_3_1', name: '28-32周超声监测宫颈口有无扩张_3 无', value: '' },
                { item: 'gongjingjiance', label: '2832UMDOC_YN_3_2', name: '28-32周超声监测宫颈口有无扩张_3 宫颈内口扩张', value: '' },
                { item: 'gongjingjiance', label: '2832UMDOC_YN_3_3', name: '28-32周超声监测宫颈口有无扩张_3 宫颈外口扩张', value: '' },
                { item: 'gongjingjiance', label: '2832_MOCO_3', name: '28-32宫颈内口形态_3 (0=T 1=Y 3=V 4=U )', value: '' },
                { item: 'gongjingjiance', label: '2832_diameter_3', name: '28-32直径_3', value: '' },
                { item: 'gongjingjiance', label: '2832_depth_3', name: '28-32深度_3', value: '' },
                { item: 'gongjingjiance', label: '2832_ELOC_3', name: '28-32周宫颈有效长度_3', value: '' },
                { item: 'gongjingjiance', label: '2832_HP_YN_3', name: '28-32周是否存在高回声沉淀_3 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2832_EL_3', name: '28-32周宫颈外口扩张大小_3', value: '' },
                { item: 'gongjingjiance', label: '2832_VEDOC_YN_3', name: '28-32周阴道检查宫口有无扩张_3 (0=无 1=有 )', value: '' },
                { item: 'gongjingjiance', label: '2832_cerclage_Cervical_length_3', name: '28-32环扎线下宫颈长度_3', value: '' },
                { item: 'gongjingjiance', label: 'MLOCDP', name: '孕期宫颈最短长度', value: '' },
                { item: 'gongjingjiance', label: 'MLOCDP_GW', name: '孕期宫颈最短长度对应孕周', value: '' },
                { item: 'gongjingjiance', label: 'MLOCDP_GD', name: '孕期宫颈最短长度对应孕周天数', value: '' },
                { item: 'weichanersuifang', label: 'Newborn_Sex', name: '新生儿性别 (1=男 2=女 )', value: '' },
                { item: 'weichanersuifang', label: 'Birth_Weight', name: '出生体重', value: '' },
                { item: 'weichanersuifang', label: 'Newborn_Survival', name: '新生儿存活状况 (1=存活 2=死胎 3=死产 )', value: '' },
                { item: 'weichanersuifang', label: 'Apgar_Score_1min', name: '1min Apgar 评分', value: '' },
                { item: 'weichanersuifang', label: 'Apgar_Score_5min', name: '5min Apgar 评分', value: '' },
                { item: 'weichanersuifang', label: 'Apgar_Score_10min', name: '10min Apgar 评分', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_1', name: '新生儿严重不良预后 入住NICU/新生儿高危病房', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2', name: '新生儿严重不良预后 转入医院', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_3', name: '新生儿严重不良预后 呼吸窘迫综合征（机械通气或插管）', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_4', name: '新生儿严重不良预后 呼吸衰竭', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_5', name: '新生儿严重不良预后 脑出血', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_6', name: '新生儿严重不良预后 坏死性小肠炎', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_7', name: '新生儿严重不良预后 早产儿视网膜病变', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_8', name: '新生儿严重不良预后 贫血', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_9', name: '新生儿严重不良预后 败血症', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_10', name: '新生儿严重不良预后 房间隔缺损', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_11', name: '新生儿严重不良预后 动脉导管未闭', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_12', name: '新生儿严重不良预后 卵圆孔未闭', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_13', name: '新生儿严重不良预后 严重畸形', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_14', name: '新生儿严重不良预后 新生儿死亡', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_15', name: '新生儿严重不良预后 其他', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_16', name: '新生儿严重不良预后 无', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_17', name: '新生儿严重不良预后 新生儿脑病', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_18', name: '新生儿严重不良预后 支气管肺发育不良', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_19', name: '新生儿严重不良预后 听力受损', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_20', name: '新生儿严重不良预后 肺动脉高压', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_21', name: '新生儿严重不良预后 围生期感染', value: '' },
                { item: 'weichanersuifang', label: 'OTH_Newborn_SAP', name: '其他新生儿严重不良预后', value: '' },
                { item: 'weichanersuifang', label: 'Stay_NICU_Days', name: '入住NICU/新生儿高危病房天数', value: '' },
                { item: 'weichanersuifang', label: 'TTH', name: '转入医院 (1=复旦儿科 2=儿中心 3=儿童医院 4=一妇婴 5=其他 )', value: '' },
                { item: 'weichanersuifang', label: 'OTH_Hospital', name: '其他医院', value: '' },
                { item: 'weichanersuifang', label: 'Newborn_Death_DAT', name: '新生儿死亡日期-1', value: '' },
                { item: 'weichanersuifang', label: 'Newborn_Death_Days', name: '死亡距出生天数', value: '' },
                { item: 'weichanersuifang', label: 'Stillbirth_Causes', name: '死胎死产原因 (1=FGR（胎儿宫内生长受限） 2=胎盘早剥 3=感染 4=脐带因素 5=妊娠期高血压疾病 6=ICP 7=胎儿染色体异常 8=胎儿畸形 9=其他 10=不明原因死亡 )', value: '' },
                { item: 'weichanersuifang', label: 'OTH_Stillbirth_Causes', name: '其他死胎死产原因', value: '' },
                { item: 'weichanersuifang', label: 'children_diagnosis_2', name: '新生儿出院小结2', value: '' },
                { item: 'weichanersuifang', label: '3months_Survival', name: '出生3个月婴儿存活情况 (1=存活 2=死亡 )', value: '' },
                { item: 'weichanersuifang', label: 'ADD1', name: '添加按钮 (1=<span style="font-size:20px;">点击添加</span> )', value: '' },
                { item: 'weichanersuifang', label: 'Newborn_Sex_2', name: '新生儿性别 (1=男 2=女 )', value: '' },
                { item: 'weichanersuifang', label: 'Birth_Weight_2', name: '出生体重', value: '' },
                { item: 'weichanersuifang', label: 'Newborn_Survival_2', name: '新生儿存活状况 (1=存活 2=死胎 3=死产 )', value: '' },
                { item: 'weichanersuifang', label: 'Apgar_Score_1min_2', name: '1min Apgar 评分', value: '' },
                { item: 'weichanersuifang', label: 'Apgar_Score_5min_2', name: '5min Apgar 评分', value: '' },
                { item: 'weichanersuifang', label: 'Apgar_Score_10min_2', name: '10min Apgar 评分', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_1', name: '新生儿严重不良预后 入住NICU/新生儿高危病房', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_2', name: '新生儿严重不良预后 转入医院', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_3', name: '新生儿严重不良预后 呼吸窘迫综合征（机械通气或插管）', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_4', name: '新生儿严重不良预后 呼吸衰竭', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_5', name: '新生儿严重不良预后 脑出血', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_6', name: '新生儿严重不良预后 坏死性小肠炎', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_7', name: '新生儿严重不良预后 早产儿视网膜病变', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_8', name: '新生儿严重不良预后 贫血', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_9', name: '新生儿严重不良预后 败血症', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_10', name: '新生儿严重不良预后 房间隔缺损', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_11', name: '新生儿严重不良预后 动脉导管未闭', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_12', name: '新生儿严重不良预后 卵圆孔未闭', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_13', name: '新生儿严重不良预后 严重畸形', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_14', name: '新生儿严重不良预后 新生儿死亡', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_15', name: '新生儿严重不良预后 其他', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_16', name: '新生儿严重不良预后 无', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_17', name: '新生儿严重不良预后 新生儿脑病', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_18', name: '新生儿严重不良预后 支气管肺发育不良', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_19', name: '新生儿严重不良预后 听力受损', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_20', name: '新生儿严重不良预后 肺动脉高压', value: '' },
                { item: 'weichanersuifang', label: 'NewbornSAP_2_21', name: '新生儿严重不良预后 围生期感染', value: '' },
                { item: 'weichanersuifang', label: 'OTH_Newborn_SAP_2', name: '其他新生儿严重不良预后', value: '' },
                { item: 'weichanersuifang', label: 'Stay_NICU_Days_2', name: '入住NICU/新生儿高危病房天数', value: '' },
                { item: 'weichanersuifang', label: 'TTH_2', name: '转入医院 (1=复旦儿科 2=儿中心 3=儿童医院 4=一妇婴 5=其他 )', value: '' },
                { item: 'weichanersuifang', label: 'OTH_Hospital_', name: '其他医院名称', value: '' },
                { item: 'weichanersuifang', label: 'admission_name', name: '住院名字', value: '' },
                { item: 'weichanersuifang', label: 'admission_number', name: '住院号', value: '' },
                { item: 'weichanersuifang', label: 'Newborn_Death_DAT_2', name: '新生儿死亡日期-2', value: '' },
                { item: 'weichanersuifang', label: 'Newborn_Death_Days_2', name: '死亡距出生天数', value: '' },
                { item: 'weichanersuifang', label: 'Stillbirth_Causes_2', name: '死胎死产原因 (1=FGR（胎儿宫内生长受限） 2=胎盘早剥 3=感染 4=脐带因素 5=妊娠期高血压疾病 6=ICP 7=胎儿染色体异常 8=胎儿畸形 9=其他 10=不明原因死亡 )', value: '' },
                { item: 'weichanersuifang', label: 'OTH_Stillbirth_Causes_2', name: '其他死胎死产原因', value: '' },
                { item: 'weichanersuifang', label: 'children_diagnosis', name: '新生儿出院小结2', value: '' },
                { item: 'weichanersuifang', label: '3months_Survival_2', name: '出生3个月婴儿存活情况 (1=存活 2=死亡 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'GlucocorticoidType_1_1', name: '糖皮质激素使用类型_1 倍他米松', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'GlucocorticoidType_1_2', name: '糖皮质激素使用类型_1 地塞米松', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Glucocorticoid_STUGW_date_1', name: '糖皮质激素开始使用时间_周_1', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Glucocorticoid_STUGW_Week_1', name: '糖皮质激素开始使用孕周_周_1', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Glucocorticoid_STUGW_Day_1', name: '糖皮质激素开始使用孕周_天_1', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Glucocorticoid_FC_YN_1', name: '糖皮质激素是否足疗程_1 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'AFHRM_YN_1', name: '胎心监护有无异常_1 (1=有 0=无 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Glucocorticoid_2_YN', name: '糖皮质激素是否使用第二疗程 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'GlucocorticoidType_2_1', name: '糖皮质激素第二疗程使用类型 倍他米松', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'GlucocorticoidType_2_2', name: '糖皮质激素第二疗程使用类型 地塞米松', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Glucocorticoid_STUGW_date_2', name: '糖皮质激素开始使用时间_周_2', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Glucocorticoid_STUGW_Week_2', name: '糖皮质激素第二疗程开始使用孕周_周', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Glucocorticoid_SC_STUGW_Day_2', name: '糖皮质激素第二疗程开始使用孕周_天', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'Glucocorticoid_FC_YN_2', name: '糖皮质激素第二疗程是否足疗程 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'AFHRM_YN_2', name: '胎心监护第二疗程有无异常 (1=有 0=无 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'MSBP_YN', name: '硫酸镁脑保护 (1=是 0=否 )', value: '' },
                { item: 'zhuyuan_renshenjieju', label: 'pwpif_YN', name: '孕期未促肺 (1=是 0=否 3=未知 )', value: '' },
            ],
            BabyInfo_key: {
                general_risk_evaluation: '一般风险评估',
                zaochanfengxian_history: '早产风险(既往史)',
                zaochanfengxian_current: '早产风险(现病史)',
                zhuyuan_huanzha: '住院-环扎',
                gongjingjiance: '宫颈监测',
                weichanersuifang: '围产儿随访',
                zhuyuan_renshenjieju: '住院—妊娠结局',
            },
            labelStyle_baby: {
                'width': '300px',
                'color': '#606266'
            },
            infoStyle_baby: {
                'color': '#606266'
            },

        };
    },
    //监听属性 类似于data概念
    computed: {
        filteredBabyInfo() {
            let grouped = {};
            let filtered = {};

            this.BabyInfo = this.BabyInfo
                .filter(item => !item.name.includes('添加按钮')) // 过滤掉错误项
                .map(item => ({
                    ...item,
                    name: item.name
                        .replace(/<[^>]+>/g, '')  // 去掉 HTML 标签
                        .replace(/&nbsp;/g, '')  // 去掉 `&nbsp;`
                        .replace(/\s+/g, '') // 去掉所有空白字符
                }));

            // 先按照 item 分组
            this.BabyInfo.forEach((item) => {
                if (!grouped[item.item]) {
                    grouped[item.item] = [];
                }
                grouped[item.item].push(item);
            });

            // 处理相同前缀（去除 _数字），若所有值为空，仅保留一次
            Object.keys(grouped).forEach((key) => {
                let tempMap = {};
                grouped[key].forEach((item) => {
                    let baseLabel = item.label.replace(/_\d+$/, '');
                    if (!tempMap[baseLabel]) {
                        tempMap[baseLabel] = [];
                    }
                    tempMap[baseLabel].push(item);
                });

                filtered[key] = [];
                Object.values(tempMap).forEach((items) => {
                    let hasNonEmpty = items.some(i => i.value);
                    if (hasNonEmpty) {
                        filtered[key].push(...items);
                    } else {
                        filtered[key].push({ ...items[0], value: '' });
                    }
                });
            });

            return filtered;
        }
    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        getInformation() {
            // console.log(this.uid)
            this.$http({
                url: this.$http.adornUrl('/personal/info/app/getPersonalInfo'),
                method: 'get',
                params: this.$http.adornParams({ uid: this.uid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据")
                    console.log(data)
                    let info_basic = data.info
                    let user_basic = data.user
                    let familyDisease = JSON.parse(info_basic.familyDisease) //家族史
                    let module_ls = [this.basic, this.Environment, this.History, this.Family, this.Other]
                    let name_ls = [info_basic, user_basic, familyDisease]
                    let gendermap = { 0: "女", 1: "男" }

                    console.log(info_basic["insurancePaymentMode"].replace(/\["/g, "").replace(/\"]/g, ""))

                    // 赋值
                    // 1.基本信息+家族史+其它史（这些的label都是固定的）
                    for (let k = 0; k < name_ls.length; k++) {
                        for (let i = 0; i < module_ls.length; i++) {
                            for (let j = 0; j < module_ls[i].length; j++) {
                                let name = module_ls[i][j]["name"]
                                if ((name in name_ls[k])) {
                                    if (name == 'gender') {
                                        if (name_ls[k][name] == 1) {
                                            this.genderFlag = false   // 性别标签，用于控制是否显示【怀孕情况】
                                        }
                                        module_ls[i][j]["value"] = gendermap[name_ls[k][name]]
                                    } else {
                                        if (typeof (name_ls[k][name]) == 'string') {
                                            // 去掉某些字符串中的[]"\
                                            module_ls[i][j]["value"] = name_ls[k][name].replace(/\[/g, "").replace(/\]/g, "").replace(/\\"/g, "").replace(/\"/g, "")
                                        } else {
                                            module_ls[i][j]["value"] = name_ls[k][name]
                                        }

                                    }
                                    if (!name_ls[k][name] || name_ls[k][name] == 'null') // 非空判断
                                    {
                                        module_ls[i][j]["value"] = "-"
                                        continue
                                    }
                                }
                            }
                        }
                    }
                    // 2.疾病、手术、外伤、输血（label不固定）
                    let diseaseHistory = JSON.parse(info_basic.diseaseHistory) //疾病
                    let operationHistory = JSON.parse(info_basic.operationHistory) //手术
                    let injuryHistory = JSON.parse(info_basic.injuryHistory) //外伤
                    let transfusionHistory = JSON.parse(info_basic.transfusionHistory) //输血
                    let obtainedData = [diseaseHistory, operationHistory, injuryHistory, transfusionHistory]
                    let formItem = ['疾病', '手术', '外伤', '输血']
                    var History_list = new Array();
                    // 把key值写进label，把时间写进value
                    for (let k = 0; k < obtainedData.length; k++) {
                        let key = Object.keys(obtainedData[k])
                        for (let i = 0; i < key.length; i++) {
                            let obj = { item: formItem[k], label: key[i], value: obtainedData[k][key[i]] }
                            History_list.push(obj)
                            this.getSpanArr(History_list) //按item字段合并后的数组
                        }
                    }

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },

        // 表头样式
        tableHeaderCellStyle() {
            return "background:#fafafa"
        },
        // 既往史合并方法
        // step1.合并名称相同的列-设置单元格（CSDN https://blog.csdn.net/weixin_46565787/article/details/122084219）
        getSpanArr(list) {
            // 先给所有的数据都加一个v.rowspan = 1
            list.forEach(item => {
                item.rowspan = 1;
            });
            // 双层循环
            for (let i = 0; i < list.length; i++) {
                for (let j = i + 1; j < list.length; j++) {
                    //此处可根据相同字段进行合并，此处是根据的id
                    if (list[i].item === list[j].item) {
                        list[i].rowspan++;
                        list[j].rowspan--;
                    }
                }
                // 这里跳过已经重复的数据
                i = i + list[i].rowspan - 1;
            }
            this.History = list;
        },
        // step2. 设置el-table :span-method="objectSpanMethod"
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                return {
                    rowspan: row.rowspan,
                    colspan: 1
                };
            }
        },

        handleFileChange(file) {
            this.file = file.raw; // 存储文件
            this.uploadFile();
        },
        uploadFile() {
            if (!this.file) {
                this.$message.warning('请先选择文件');
                return;
            }

            // 将获取到的excel传到服务器中计算
            let formData = new FormData();
            formData.append("uid", this.uid);
            formData.append("file", this.file);
            this.$http({
                url: this.$http.adornUrl("/personal/babyinfo/uploadExcel2Info"),
                method: "post",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.$message({
                        message: '成功存储',
                        type: 'success'
                    })
                    this.getBabyInfo()

                }
                else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            })
        },
        getBabyInfo() {
            this.$http({
                url: this.$http.adornUrl('/personal/babyinfo/getConclusion'),
                method: 'get',
                params: this.$http.adornParams({ uid: this.uid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据")
                    console.log(data)
                    let info_basic = data.info
                    // 2.疾病、手术、外伤、输血（label不固定）
                    let generalRiskEvaluation = JSON.parse(info_basic.generalRiskEvaluation)
                    let zaochanfengxianHistory = JSON.parse(info_basic.zaochanfengxianHistory)
                    let zaochanfengxianCurrent = JSON.parse(info_basic.zaochanfengxianCurrent)
                    let gongjingjiance = JSON.parse(info_basic.gongjingjiance)
                    let zhuyuanHuanzha = JSON.parse(info_basic.zhuyuanHuanzha)
                    let zhuyuanRenshenjieju = JSON.parse(info_basic.zhuyuanRenshenjieju)
                    let weichanersuifang = JSON.parse(info_basic.weichanersuifang)
                    // 以上内容合并成一个大的json
                    let obtainedData = [].concat(
                        generalRiskEvaluation,
                        zaochanfengxianHistory,
                        zaochanfengxianCurrent,
                        gongjingjiance,
                        zhuyuanHuanzha,
                        weichanersuifang,
                        zhuyuanRenshenjieju
                    );
                    this.BabyInfo = obtainedData
                    console.log(this.BabyInfo)
                    // let formItem = ['一般风险评估', '早产风险(既往史)', '早产风险(现病史)', '住院-环扎','宫颈监测','围产儿随访','住院—妊娠结局']
                    // var History_list = new Array();
                    // // 把key值写进label，把时间写进value
                    // for (let k = 0; k < obtainedData.length; k++) {
                    //     let key = Object.keys(obtainedData[k])
                    //     for (let i = 0; i < key.length; i++) {
                    //         let obj = { item: formItem[k], label: key[i], value: obtainedData[k][key[i]] }
                    //         History_list.push(obj)
                    //         this.getSpanArr(History_list) //按item字段合并后的数组
                    //     }
                    // }

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        }

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        if (this.$store.state.module.moduleName === 'women_baby') {
            this.uid = this.$store.state.women_baby.uid
        }
        console.log(this.uid)


    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        console.log(this.$route.query);
        if (this.$route.query.index) {
            console.log("看看是否传参：");
            console.log(this.$route.query.index);
            this.uid = this.$route.query.index

        }
        this.getInformation();
        this.getBabyInfo();
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style>
.el-descriptions .is-bordered .el-descriptions-item__cell {
    padding: 6px 10px;
}

.aligncenter {
    clear: both;
    display: block;
    margin: auto;
}
</style>